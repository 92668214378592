import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { useEffect } from "react"
import { useState } from "react"
import { Button } from "react-bootstrap"
import NavigationBar from "../components/navigation_bar"
import { useGlobalState } from "../contexts/global_state"
import PlanInterface from "../interfaces/plan"
import { getCategories, getPlans } from "../utils/fire"
import SEO from '../components/seo';
import CategoryInterface from '../interfaces/category';
import "./store.scss"
const features = [
  {
    heading: "Quality Products",
    subHeading:
      "latest & quality assured products catered to the market demands",
    src: (
      <StaticImage
        placeholder="blurred"
        alt="Quality Products"
        src="../images/store/qualityProducts.png"
      />
    ),
  },
  {
    heading: "Free Shipping",
    subHeading: "Free shipping for all orders over 1000$",
    src: (
      <StaticImage
        placeholder="blurred"
        alt="Free Shipping"
        src="../images/store/freeShipping.png"
      />
    ),
  },
  {
    heading: "Secure payment",
    subHeading: "Guarentee 100% Secure online payment on our website",
    src: (
      <StaticImage
        placeholder="blurred"
        alt="Secure payment"
        src="../images/store/securePayments.png"
      />
    ),
  },
]
function ItemCard({ plan }: { plan: PlanInterface }) {
  const [show, setShow] = useState(false)
  const [state, dispatch] = useGlobalState()
  const addedToCart = state.cart.some(plan2 => plan2.id === plan.id)
  const addToCart = (plan: PlanInterface) => () => {
    dispatch({ type: "ADD_TO_CART", payload: plan })
  }
  return (
    <div>
      {!show && (
        <div className="d-flex flex-column align-items-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
          <div className="m-2">Loading Plan</div>
        </div>
      )}
      <div className={"itemCard " + (!show ? "d-none" : "")}>
        <div className="text-container">
          <img
            onLoad={() => setShow(true)}
            src={plan.imageURL}
            alt={plan.name}
          />
          <div className="planHeading">{plan.name}</div>
          <div className="planPrice">${plan.price}</div>
        </div>
        {addedToCart ? (
          <Button
            onClick={() =>
              dispatch({ type: "REMOVE_FROM_CART", payload: plan.id })
            }
            variant="danger"
            className="btn-block removeFromCart"
          >
            Remove From Cart
          </Button>
        ) : (
          <Button
            disabled={addedToCart}
            onClick={addToCart(plan)}
            className="addToCart"
          >
            Add to cart
          </Button>
        )}
      </div>
    </div>
  )
}
export default function Store() {
  const [categories, setCategories] = useState<CategoryInterface[]>()
  const [plans, setPlans] = useState<PlanInterface[]>([])
  const [selectedCategory, setSelectedCategory] = useState("")

  useEffect(() => {
    getCategories().then(data=>data?data.categories:null).then((categories:CategoryInterface[]) => {
      if (categories) {
        setSelectedCategory((categories)[0].id)
      }
      setCategories(categories)
    })
    getPlans().then(data => {
      setPlans(data.docs.map((doc: any) => doc.data()))
    })
  }, [])

  return (
    <div>
      <SEO title="Store"/>
      <div className="top-section-store">
        <div className="gradient">
          <NavigationBar current="/store" />
          <div className="top-section-header">
            <div className="top-section-heading">
              Shop Solar Products Online and Let the Sun Save Your Money
            </div>
            <div className="top-section-sub-heading">
              Now you can get the whole range of solar products on a single
              platform and reduce your electricity bill up to 80%. You can
              install solar systems to serve the multipurpose of saving money
              along with the conservation of the environment
            </div>
          </div>
        </div>
      </div>
      <div className="features">
        {features.map(feature => (
          <div key={feature.heading} className="feature p-2">
            <span style={{ width: 40, height: 40 }}>{feature.src}</span>

            <div className="ml-3">
              <div className="feature-heading">{feature.heading}</div>
              <div className="feature-subHeading">{feature.subHeading}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="hrr" />
      <div className="our-products">
        <div className="our-products-heading">Our Products</div>
        <div className="categories">
          {!categories && (
            <div className="d-flex flex-column align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="m-2">Loading Categories</div>
            </div>
          )}
          {categories &&
            (categories).map(category => (
              <div
                key={category.id}
                className={
                  selectedCategory === category.id
                    ? "activeCategory"
                    : "inactiveCategory"
                }
                onClick={() => setSelectedCategory(category.id)}
              >
                {category.name}
              </div>
            ))}
        </div>
        <div className="plans">
          {plans.length === 0 && (
            <div className="d-flex flex-column align-items-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <div className="m-2">Loading Plans</div>
            </div>
          )}
          {plans &&
            plans
              .filter(plan => plan.category === selectedCategory&&!plan.isHidden)
              .sort((a,b)=>a.priority-b.priority)
              .map(plan => <ItemCard key={plan.id} plan={plan} />)}
        </div>
      </div>
    </div>
  )
}
